import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Hero from "../components/Hero"

function StreetBumpHero() {
  return (
    <Hero>
      <div className="hero-copy">
        <h2>Street Bump</h2>
      </div>
    </Hero>
  )
}

function StreetBumpPage() {
  const title = "Street Bump"

  return (
    <Layout>
      <SEO title={title} />
      <StreetBumpHero />
      <div className="container examples">
        <div className="row header">
          <h3>What is Street Bump?</h3>
          <p>
            Street Bump was a crowd-sourcing project that helped residents
            improve their neighborhood streets. Volunteers used the Street Bump
            mobile app to collect road condition data while they drive. The data
            provided governments with real-time information to fix problems and
            plan long term investments.
          </p>
          <p>
            <iframe
              src="//player.vimeo.com/video/38233136?byline=0&amp;portrait=0&amp;color=70b89b"
              width="100%"
              height="320"
              frameborder="0"
              webkitAllowFullScreen
              mozallowfullscreen
              allowFullScreen
            ></iframe>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default StreetBumpPage
